// components
import Utm from "../components/_global/utm";

// images
import TopBackground from "../images/fateks/fateks-kumas-header-image.jpg";
import Dollar from "../images/confi/dollar.svg";
import RFQs from "../images/fateks/RFQs.svg";
import CheckIcon from "../images/icon/check.svg";
import Facts from "../images/confi/location.svg";
import Handshake from "../images/confi/handshake.svg";
import Size from "../images/confi/size.svg";
import Profile from "../images/confi/profile.svg";
import Foursource from "../images/confi/foursource.svg";
import Apparel from "../images/confi/apparel.svg";
import ApparelManufacturer from "../images/confi/apparelManufacturer.svg";
import Banner from "../images/confi/banner.svg";
import Giulio from "../images/fateks/fateks-kumas-logo.png";


export const pageTexts = (t) => {
  return {
    topInfo: {
      title: {
        text: t("fateks-kumas.topTitle"),
        colorClass: "colorLight",
      },
      subtitle: {
        text: t("fateks-kumas.subTitle"),
        colorClass: "colorLight",
      },
      bottomDiagonal: true,
      opacity: true,
      image: {
        src: TopBackground,
        alt: t("fateks-kumas.imgAlt"),
      },
    },
    topIcons: [
      {
        title: {
          text: t("fateks-kumas.newMarketsValue"),
        },
        subtitle: {
          text: t("fateks-kumas.newMarketsSubtitle"),
          colorClass: "colorDark text-center",
        },
        icon: {
          src: Dollar,
          alt: t("fateks-kumas.newMarketsAlt"),
        },
      },
      {
        title: {
          text: t("fateks-kumas.roiValue"),
          isNum: true,
        },
        subtitle: {
          text: t("fateks-kumas.roiSubtitle"),
          colorClass: "colorDark text-center",
        },
        icon: {
          src: Handshake,
          alt: t("fateks-kumas.roiAlt"),
        },
      },
      {
        title: {
          text: t("fateks-kumas.recruitingValue"),
        },
        subtitle: {
          text: t("fateks-kumas.recruitingSubtitle"),
          colorClass: "colorDark text-center",
        },
        icon: {
          src: RFQs,
          alt: t("fateks-kumas.recruitingAlt"),
        },
      },
    ],
    sideIcons1: [
      {
        title: {
          text: t("fateks-kumas.locationTitle"),
        },
        subtitle: {
          text: t("fateks-kumas.locationSubtitle"),
          colorClass: "colorDarkLight",
        },
        icon: {
          src: Facts,
          alt: t("fateks-kumas.locationAlt"),
        },
      },
      {
        title: {
          text: t("fateks-kumas.productTitle"),
        },
        subtitle: {
          text: t("fateks-kumas.productSubtitle"),
          colorClass: "colorDarkLight",
        },
        icon: {
          src: Apparel,
          alt: t("fateks-kumas.productAlt"),
        },
      },
      {
        title: {
          text: t("fateks-kumas.memberTitle"),
        },
        subtitle: {
          text: t("fateks-kumas.memberSubtitle"),
          colorClass: "colorDarkLight",
        },
        icon: {
          src: ApparelManufacturer,
          alt: t("fateks-kumas.memberAlt"),
        },
      },
      {
        title: {
          text: t("fateks-kumas.membershipTitle"),
        },
        subtitle: {
          text: t("fateks-kumas.membershipSubtitle"),
          colorClass: "colorDarkLight",
        },
        icon: {
          src: Profile,
          alt: t("fateks-kumas.membershipAlt"),
        },
      },
      {
        title: {
          text: t("fateks-kumas.sizeTitle"),
        },
        subtitle: {
          text: t("fateks-kumas.sizeSubtitle"),
          colorClass: "colorDarkLight",
        },
        icon: {
          src: Size,
          alt: t("fateks-kumas.sizeAlt"),
        },
      },
      {
        title: {
          text: t("fateks-kumas.sinceTitle"),
        },
        subtitle: {
          text: t("fateks-kumas.sinceSubtitle"),
          colorClass: "colorDarkLight",
        },
        icon: {
          src: Foursource,
          alt: t("fateks-kumas.sinceAlt"),
        },
      },
    ],
    sideIcons2: [
      {
        title: {
          text: t("fateks-kumas.challengesSquareTitle"),
          textSize: "font24 lineHeight1_3 ",
        },
        subtitle: {
          text: t("fateks-kumas.challengesSquareSubtitle"),
          colorClass: "colorDark",
          textSize: "font17  pt-10"
        },
        icon: {
          src: '',
          alt: t("fateks-kumas.newMarketsAlt"),
        },
      },
    ],
    sideIcons3: [
      {
        title: {
          text: t("fateks-kumas.favoriteToolsTitle"),
          textSize: "font24 lineHeight1_3 ",
        },
        subtitle: {
          text: t("fateks-kumas.favoriteToolsSubtitle"),
          colorClass: "colorDark",
          textSize: " font17 pt-20"
        },
        icon: {
          src: '',
          alt: t("fateks-kumas.favoriteToolsAlt"),
        },
        banner: true,
        bannerSrc: Banner,
        bannerAlt: "Banner"
      },
    ],
    giulio: [
      {
        title: {
          text: t("fateks-kumas.giulioName"),
        },
        subtitle: {
          text: t("fateks-kumas.giulioTitle"),
          colorClass: "colorDarkLight",
        },
        icon: {
          src: Giulio,
          alt: t("fateks-kumas.newMarketsAlt"),
        },
      },
    ],
    aboutInfo: {
      title: {
        text: t("fateks-kumas.aboutTitle"),
        colorClass: "colorDark",
        extraClass: "font40",
      },
      text: {
        text: t("fateks-kumas.aboutText"),
        colorClass: "colorDark",
        textType: "paragraph",
      },
      separator: true,
    },
    challengesInfo: {
      title: {
        text: t("fateks-kumas.challengesTitle"),
        colorClass: "colorDark",
        extraClass: "font40",
      },
      text: {
        text: t("fateks-kumas.challengesText"),
        colorClass: "colorDark",
        textType: "paragraph",
      },
      separator: true,
    },
    findingInfo: {
      title: {
        text: t("fateks-kumas.findingTitle"),
        colorClass: "colorDark",
        extraClass: "font40",
      },
      text: {
        text: t("fateks-kumas.findingText"),
        colorClass: "colorDark",
        textType: "paragraph",
      },
      separator: true,
    },
    resultsInfo: {
      title: {
        text: t("fateks-kumas.resultsTitle"),
        colorClass: "colorDark",
        extraClass: "font40",
      },
      text: {
        text: t("fateks-kumas.resultsText"),
        colorClass: "colorDark",
        textType: "paragraph",
      },
      separator: true,
    },
    bullet: {
      icon: { fontAwesome: false, src: CheckIcon, alt: t("homepage.checkAlt") },
      separator: false,
      item: {
        text: [
          t("fateks-kumas.bullet1"),
          t("fateks-kumas.bullet2"),
          t("fateks-kumas.bullet3"),
        ],
        colorClass: "colorDark",
      },
      customPBText: true,
    },
    bottomText:  [
      {
        text: t("fateks-kumas.bulletBottomText"),
        colorClass: "colorDark",
        textType: "paragraph",
      },
    ],
    cta: {
      text: t("fateks-kumas.cta1"),
      url: "https://foursource.com/apparel-manufacturers/",
      type: "whiteColor",
    },
    cta2: {
      text: t("fateks-kumas.cta2"),
      url: process.env.GATSBY_CHINA === "true" ? `${process.env.APP_CHINA_URL}/signup/` : Utm(`${process.env.APP_URL}/signup/`),
      type: "mainColor",
    },
    squareText: t("fateks-kumas.challengesSquareSubtitle2"),
    favoriteToolsExtra: t("fateks-kumas.favoriteToolsExtra"),
    profileText: t("fateks-kumas.profileText"),
    testimonyName: t("fateks-kumas.giulioName"),
    testimonyTitle: t("fateks-kumas.giulioTitle"),
    anchorText: t("fateks-kumas.anchorText")
  };
};
